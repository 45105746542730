<template>
  <div id="app" v-cloak @touchmove.prevent>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
[v-cloak] {
  display: none;
}

#app {
  position: relative;
  width: 100%;
  height: 100vh;
}
</style>
