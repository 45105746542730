import Vue from 'vue'
import ViewUI from 'view-design'
import router from './index'

Vue.use(ViewUI)

// 路由前置守卫
router.beforeEach((to, from, next) => {
  ViewUI.LoadingBar.start()
  next()
})

router.afterEach(route => {
  ViewUI.LoadingBar.finish()
})
